.items {
  max-height: 700px;
  overflow: auto;
  -webkit-box-shadow: inset 0 8px 18px -12px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0 8px 18px -12px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 8px 18px -12px rgba(0, 0, 0, 0.25);
}

.item {
  line-height: 25px;
  margin: 10px 0;
  padding: 10px;
  -webkit-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:hover {
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.98);
  }

  h4 {
    cursor: pointer;
    padding-right: 30px;

    &:hover:after {
      content: '';
      position: absolute;
      right: 0;
      top: 2px;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #dedede;
      border-radius: 50px;
      background: white url("../../image/edit.png") no-repeat center;
      background-size: 12px;
    }
  }
}

.items .item {
  margin: 10px;
}

.deleted {
  opacity: 0.2;

  h4:after {
    display: none !important;
  }
}

.used {
  color: #8182a7;
}

.editBox {
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 15px;

  input {
    width: 100%;
    margin-right: 10px;
  }
}

.values {
  position: relative;
}

.buttons {
  opacity: 0;
  button {
    margin: 10px;
  }
}

.item:not(.deleted):hover .buttons {
  opacity: 1;
}

.itemDate {
  display: block;
}

.filters {
  margin: 10px 0;

  label {
    font-size: 14px;
  }
}

.filtersSearch {
  margin: 25px 0 7px;
  position: relative;

  input {
    width: 100%;
    margin: 0;
    padding: 15px;
    font-size: 16px;
    border: 1px solid #e4e4e4;
  }

  input + button {
    position: absolute;
    right: 0;
    margin: 15px;
    padding: 0;
    font-size: 20px;
    line-height: 18px;
    background: transparent;

    &:hover {
      transform: scale(1.4);
    }
  }

  span {
    font-size: 9px;
    color: red;
    position: absolute;
    left: 16px;
    bottom: 5px;
  }
}

.pagination {
  padding: 15px;
}

.addButton {
  margin-right: 0;
}