.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    -webkit-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
    background: white;
  }
  input, button {
    margin: 10px;
  }
}