@import "src/container/Table/Table.module";

.item {
  line-height: 35px;
}

.addButton {
  margin-right: 0;
  margin-left: 0;
}

.editForm {
  position: relative;
  margin-bottom: 35px;
}

.rulesList {
  font-size: 11px;
  margin: 10px 0;

  label {
    background: #dedede;
    padding: 3px;
    border-radius: 6px;
    margin: 1px 15px 4px 0;
    display: inline-block;
  }

  input {
    margin: 0 0 0 5px;
  }
}

.userPermission {
  background: #f9f9f9;
  padding: 10px 10px 1px 10px;
  margin: 10px 0 10px 0;
  font-size: 12px;

  button {
    opacity: 0;
  }

  &:hover button {
    opacity: 1;
  }
}

.itemNewRules {
  margin: 10px 0;
  padding: 10px 10px 0 10px;
  background: whitesmoke;
}

.labelSectionRules {
  width: 100%;
  display: block;
  color: #7784a0;
}