.App {
  display: initial;

  & > header {
    margin-bottom: 30px;
  }
}

.app-content {
  display: flex;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 680px;
    height: 100%;
  }
}

div {
  box-sizing: border-box;
}

input, button {
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
}

input[type=file] {
  padding: 0;
}

button {
  border: 0;
  background: #eaeaea;
  outline: none;
  cursor: pointer;

  &:hover {
    background: aliceblue;
  }

  &:active {
    transform: scale(0.90)
  }
}

header button {
  margin: 10px;
}

header div:nth-child(1) {
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-menu {
  display: flex;
  justify-content: center;

  a {
    margin: 10px;
    background: #f9f9f9;
    padding: 10px;
    color: #020202;
  }
}

#loader {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  width: 80px;
  height: 80px;
  background: white url("./loader.gif") no-repeat center;
  background-size: contain;
  border-radius: 100px;
}

#blockScreen {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.88);
  color: #020202;
  z-index: 9;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

#global-message {
  position: fixed;
  top: 50%;
  left: 50%;
  background: white;
  border-radius: 30px;
  padding: 20px;
  z-index: 10;
  text-align: center;
  -webkit-box-shadow: 0 20px 49px 7px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 20px 49px 7px rgba(0, 0, 0, 0.25);
  box-shadow: 0 20px 49px 7px rgba(0, 0, 0, 0.25);
  -webkit-animation-iteration-count: 1 !important;
  -webkit-animation-fill-mode: forwards !important;
  animation: show 300ms infinite ease-in-out;
}

@keyframes show {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
  }
  60% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}


/* GLOBAL */
.d-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}