@import "src/container/TableItem/TableItem.module";
.item {
  margin: 10px 0;
  padding: 10px 15px;
  -webkit-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    opacity: 0;
    min-width: 100px;
  }

  &:hover {
    transform: scale(1.01);
  }

  &:hover button{
    opacity: 1;
  }

  &:active {
    transform: scale(0.98);
  }

  h4{position: relative;}
}

.loadMode {
  background: #f3f3f3;
  padding: 10px;
}

.id {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #7d7b7b;
}